module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "feedback-knowledge-js": "static-1.1972",
      "browserslist-config-hubspot": "static-1.124",
      "csstype": "static-1.8",
      "head-dlb": "static-1.1528",
      "HeadJS": "static-2.469",
      "hs-test-utils": "static-1.4183",
      "hubspot-dlb": "static-1.1473",
      "I18n": "static-7.1205",
      "i18n-data": "static-1.177",
      "jasmine": "static-4.1007",
      "jasmine-runner": "static-1.2713",
      "moment-timezone": "static-5.17",
      "outpost_static": "static-1.1257",
      "PatternValidationJS": "static-1.437",
      "quartz": "static-1.3026",
      "react-utils": "static-2.2319",
      "StyleGuideUI": "static-3.412",
      "testing-library": "static-1.102",
      "react": "static-7.131",
      "enviro": "static-4.251",
      "hs-promise-rejection-tracking": "static-1.1600",
      "PortalIdParser": "static-2.230",
      "raven": "static-3.1644",
      "raven-hubspot": "static-1.1951",
      "classnames": "static-2.10",
      "hoist-non-react-statics": "static-3.9",
      "hub-http": "static-1.2655",
      "hubspot-url-utils": "static-1.1102",
      "immutable": "static-2.19",
      "metrics-js": "static-1.5598",
      "react-dom": "static-7.85",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "moment": "static-3.26"
    },
    "depPathPrefixes": {
      "feedback-knowledge-js": "/feedback-knowledge-js/static-1.1972",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.124",
      "csstype": "/csstype/static-1.8",
      "head-dlb": "/head-dlb/static-1.1528",
      "HeadJS": "/HeadJS/static-2.469",
      "hs-test-utils": "/hs-test-utils/static-1.4183",
      "hubspot-dlb": "/hubspot-dlb/static-1.1473",
      "I18n": "/I18n/static-7.1205",
      "i18n-data": "/i18n-data/static-1.177",
      "jasmine": "/jasmine/static-4.1007",
      "jasmine-runner": "/jasmine-runner/static-1.2713",
      "moment-timezone": "/moment-timezone/static-5.17",
      "outpost_static": "/outpost_static/static-1.1257",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "quartz": "/quartz/static-1.3026",
      "react-utils": "/react-utils/static-2.2319",
      "StyleGuideUI": "/StyleGuideUI/static-3.412",
      "testing-library": "/testing-library/static-1.102",
      "react": "/react/static-7.131",
      "enviro": "/enviro/static-4.251",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.1600",
      "PortalIdParser": "/PortalIdParser/static-2.230",
      "raven": "/raven/static-3.1644",
      "raven-hubspot": "/raven-hubspot/static-1.1951",
      "classnames": "/classnames/static-2.10",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "hub-http": "/hub-http/static-1.2655",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1102",
      "immutable": "/immutable/static-2.19",
      "metrics-js": "/metrics-js/static-1.5598",
      "react-dom": "/react-dom/static-7.85",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "moment": "/moment/static-3.26"
    },
    "project": "feedback-knowledge-js",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};